.card {
  width: 100%;

  img {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    object-fit: cover;
    height: 15rem;
    max-width:100%;
    width: 100%;
    display: block;
  }

  .content {
    background-color: #FFF;
    padding: 1.5rem 2rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    min-height: 20.5rem;

    .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 2rem;

      h2 {
        color: var(--dark-blue);
        font-size: 1rem;
        line-height: 1.5rem;
        letter-spacing: 0.07rem;
      }

      .price {
        background-color: #EBF7FF;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;

        span {
          color: var(--light-blue);
          font-weight: bold;
          font-size: 1rem;
          letter-spacing: 0.1rem;
        }
      }
    }

    .body {
      padding-bottom: 1rem;
      
      p {
        font-size: 1rem;
        color: var(--text);
        line-height: 1.5rem;
        display: inline;
      }
      button {
        border: none;
        background: transparent;
        font-size: 1rem;
        color: var(--light-blue);
        margin-left: .5rem;
        cursor: pointer;
      }
    }
    
    .footer {
      text-align: center;
      
      button {
        height: 2.5rem;
        font-size: 1rem;
        background: transparent;
        cursor: pointer;
        color: var(--red);
        border: 1px solid var(--red);
        padding: 0 1rem;
        border-radius: 0.25rem;
        width: 50%;

        &:hover {
          background: var(--red);
          color: var(--light-gray);
          transition: background .6s;
        }
      }
    }
  }
}